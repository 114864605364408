<template>
  <v-row align="center" justify="center" class="g-phone" no-gutters>
    <country-selector
      v-model="selectedCountry"
      :disabled="disabled"
      :country-data="countryData"
      class="mb-7 country-selector-wrapper"
    />
    <v-text-field
      :value="phoneNumber"
      :rules="computedRules"
      v-bind="$attrs"
      :disabled="disabled"
      type="number"
      outlined
      placeholder="Escribe..."
      append="mdi-cellphone"
      @input="onInput"
      @blur="$emit('blur')"
    />
  </v-row>
</template>

<script>
import CountrySelector from './CountrySelector.vue';

export default {
  name: 'GPhone',
  components: {
    CountrySelector,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCountry: null,
      phoneNumber: '',
      countryData: [
        // Chile primero
        {
          name: 'Chile',
          code: 'CL',
          flag: '$flag_chile',
          prefix: '+56',
          maxDigits: 9,
        },
        // Países de la región alrededor de Chile
        {
          name: 'Argentina',
          code: 'AR',
          flag: '$flag_argentina',
          prefix: '+54',
          maxDigits: 10,
        },
        {
          name: 'Bolivia',
          code: 'BO',
          flag: '$flag_bolivia',
          prefix: '+591',
          maxDigits: 8,
        },
        {
          name: 'Perú',
          code: 'PE',
          flag: '$flag_peru',
          prefix: '+51',
          maxDigits: 9,
        },
        {
          name: 'Paraguay',
          code: 'PY',
          flag: '$flag_paraguay',
          prefix: '+595',
          maxDigits: 9,
        },
        {
          name: 'Uruguay',
          code: 'UY',
          flag: '$flag_uruguay',
          prefix: '+598',
          maxDigits: 8,
        },
        {
          name: 'Brasil',
          code: 'BR',
          flag: '$flag_brasil',
          prefix: '+55',
          maxDigits: 11,
        },
        {
          name: 'Ecuador',
          code: 'EC',
          flag: '$flag_ecuador',
          prefix: '+593',
          maxDigits: 9,
        },
        {
          name: 'Colombia',
          code: 'CO',
          flag: '$flag_colombia',
          prefix: '+57',
          maxDigits: 10,
        },
        {
          name: 'Venezuela',
          code: 'VE',
          flag: '$flag_venezuela',
          prefix: '+58',
          maxDigits: 10,
        },

        // Países del Caribe
        {
          name: 'Cuba',
          code: 'CU',
          flag: '$flag_cuba',
          prefix: '+53',
          maxDigits: 8,
        },
        {
          name: 'Haití',
          code: 'HT',
          flag: '$flag_haiti',
          prefix: '+509',
          maxDigits: 8,
        },
        // Otros países (ejemplos)
        {
          name: 'Estados Unidos',
          code: 'US',
          flag: '$flag_eua',
          prefix: '+1',
          maxDigits: 10,
        },
        {
          name: 'México',
          code: 'MX',
          flag: '$flag_mexico',
          prefix: '+52',
          maxDigits: 10,
        },
        {
          name: 'España',
          code: 'ES',
          flag: '$flag_spain',
          prefix: '+34',
          maxDigits: 9,
        },
      ],
    };
  },
  computed: {
    computedRules() {
      const {required, phoneNumber, selectedCountry} = this;
      let baseRules = [];

      // Rule for required field
      if (required) {
        baseRules.push((v) => !!v || 'Este campo es requerido');
      }

      // Rule to validate phone number length if it has value
      if (
        phoneNumber &&
        phoneNumber.length > 0 &&
        selectedCountry &&
        selectedCountry.maxDigits
      ) {
        baseRules.push((v) => {
          const digitsOnly = v.replace(/\D/g, ''); // Remove non-digit characters
          const expectedDigits = selectedCountry.maxDigits;

          // Verificar si hay caracteres no numéricos
          if (digitsOnly.length !== v.length) {
            return 'El número no debe contener caracteres especiales';
          }

          if (digitsOnly.length < expectedDigits) {
            return `El número debe tener ${expectedDigits} dígitos`;
          }

          if (digitsOnly.length > expectedDigits) {
            return `El número no debe tener más de ${expectedDigits} dígitos`;
          }

          return true;
        });
      }

      if (!required && !phoneNumber.length) {
        baseRules = [];
      }

      return baseRules;
    },
  },
  watch: {
    value: {
      handler(value) {
        const {countryData} = this;
        if (value) {
          // Find the matching country based on prefix
          const matchingCountry = countryData.find((country) =>
            value.startsWith(country.prefix)
          );
          if (matchingCountry) {
            this.selectedCountry = matchingCountry;
            this.phoneNumber = value.slice(matchingCountry.prefix.length);
          } else {
            // If no matching country, reset to default
            this.selectedCountry = countryData[0];
            this.phoneNumber = value;
          }
        } else {
          // If value is empty, reset fields
          this.selectedCountry = countryData[0];
          this.phoneNumber = '';
        }
      },
      immediate: true,
    },
    selectedCountry() {
      this.emitInput();
      this.validatePhoneNumber();
    },
    phoneNumber() {
      this.validatePhoneNumber();
    },
    computedRules: {
      handler(newRules) {
        const {phoneNumber} = this;
        const hasError = Boolean(
          newRules.length && newRules.some((rule) => rule(phoneNumber) !== true)
        );
        this.$emit('error', hasError);
      },
      immediate: true,
    },
  },
  methods: {
    onInput(value) {
      this.phoneNumber = value;
      this.emitInput();
      this.validatePhoneNumber();
    },
    emitInput() {
      const prefix = this.selectedCountry ? this.selectedCountry.prefix : '';
      this.$emit('input', `${prefix}${this.phoneNumber.replace(/\D/g, '')}`);
    },
    validatePhoneNumber() {
      const {computedRules, phoneNumber} = this;
      const hasError = Boolean(
        computedRules.length &&
          computedRules.some((rule) => rule(phoneNumber) !== true)
      );
      this.$emit('error', hasError);
    },
  },
};
</script>

<style scoped>
.g-phone {
  display: flex;
}

.country-selector {
  margin-right: 8px;
}

.country-selector-wrapper {
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.v-text-field {
  flex: 1;
}
</style>
